// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddUserDataFragment = { __typename?: 'AddUserPayload', id?: string | null, email_address?: string | null, status?: string | null, timestamp_signup?: string | null, list_id?: string | null, tags?: Array<{ __typename?: 'MailchimpTagPayload', id?: number | null, name?: string | null }> | null };

export type MailchimpEmailSubscriptionMutationVariables = Types.Exact<{
  addUserInput: Types.AddUserInput;
}>;


export type MailchimpEmailSubscriptionMutation = { __typename?: 'RootMutation', emailSubscription: { __typename?: 'EmailSubscriptionMutation', addUser: { __typename?: 'AddUserPayload', id?: string | null, email_address?: string | null, status?: string | null, timestamp_signup?: string | null, list_id?: string | null, tags?: Array<{ __typename?: 'MailchimpTagPayload', id?: number | null, name?: string | null }> | null } } };

export const AddUserDataFragmentDoc = gql`
    fragment AddUserData on AddUserPayload {
  id
  email_address
  tags {
    id
    name
  }
  status
  timestamp_signup
  list_id
}
    `;
export const MailchimpEmailSubscriptionDocument = gql`
    mutation MailchimpEmailSubscription($addUserInput: AddUserInput!) {
  emailSubscription {
    addUser(addUserInput: $addUserInput) {
      ...AddUserData
    }
  }
}
    ${AddUserDataFragmentDoc}`;
export function useMailchimpEmailSubscription(baseOptions?: Apollo.MutationHookOptions<MailchimpEmailSubscriptionMutation, MailchimpEmailSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MailchimpEmailSubscriptionMutation, MailchimpEmailSubscriptionMutationVariables>(MailchimpEmailSubscriptionDocument, options);
      }
export type MailchimpEmailSubscriptionHookResult = ReturnType<typeof useMailchimpEmailSubscription>;
export type MailchimpEmailSubscriptionMutationResult = Apollo.MutationResult<MailchimpEmailSubscriptionMutation>;
export type MailchimpEmailSubscriptionMutationOptions = Apollo.BaseMutationOptions<MailchimpEmailSubscriptionMutation, MailchimpEmailSubscriptionMutationVariables>;