import { mergeClasses } from '@expo/styleguide';
import { PropsWithChildren } from 'react';

import { BoxContentContainer } from '../Box/BoxContentContainer';
import { BoxWrapper } from '../Box/BoxWrapper';

type Props = PropsWithChildren<{
  className?: string;
  errorTheme?: boolean;
}>;

export function FormContent({ children, className, errorTheme }: Props) {
  return (
    <BoxWrapper
      className={mergeClasses(
        'rounded-b-none rounded-t-lg border-b-0',
        errorTheme && 'border-danger',
        className
      )}>
      <BoxContentContainer className="flex flex-col gap-4">{children}</BoxContentContainer>
    </BoxWrapper>
  );
}
