import { mergeClasses } from '@expo/styleguide';
import { CheckIcon } from '@expo/styleguide-icons/outline/CheckIcon';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';

import { ActivityIndicator } from '~/ui/components/ActivityIndicator';
import { Button, ButtonProps } from '~/ui/components/Button';
import { FormStates } from '~/ui/components/form/FormStates';
import { P } from '~/ui/components/text';

type Props = {
  submitButtonText?: string;
  submitButtonProps?: ButtonProps;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  formState?: FormStates;
  successMessage?: string;
  successMessageTestId?: string;
  formError?: string;
  errorTheme?: boolean;
  testID?: string;
  className?: string;
};

enum Statuses {
  IDLE,
  LOADING,
  SUCCESS,
}

let timeout: number;

export function FormFooter({
  submitButtonText = 'Save',
  secondaryButtonOnClick = () => {},
  secondaryButtonText,
  formState = FormStates.IDLE,
  successMessage = 'Saved',
  formError,
  submitButtonProps = {},
  successMessageTestId,
  errorTheme,
  testID,
  className,
}: Props) {
  const [status, setStatus] = useState(Statuses.IDLE);

  useEffect(
    function statusDidUpdate() {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = window.setTimeout(() => setStatus(Statuses.IDLE), 2500);

      if (
        formState === FormStates.IDLE &&
        status === Statuses.LOADING &&
        !formError?.trim().length
      ) {
        setStatus(Statuses.SUCCESS);
        return;
      }

      if (formState === FormStates.LOADING) {
        return setStatus(Statuses.LOADING);
      }

      return setStatus(Statuses.IDLE);
    },
    [formState]
  );

  const buttonsDisabled = formState === FormStates.LOADING;

  return (
    <div
      className={mergeClasses(
        'flex h-15 items-center justify-between rounded-b-lg border border-default border-t-secondary bg-subtle pl-6 pr-3',
        errorTheme && 'border-t-0 border-danger bg-danger',
        className
      )}>
      <div className="relative mr-4">
        <div className="absolute">
          <AnimatePresence>
            {Boolean(status === Statuses.LOADING) && (
              <motion.div
                initial={{ opacity: 0, translateY: 4 }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: -4 }}
                transition={{ duration: 0.15 }}>
                <ActivityIndicator className="stroke-icon-default" />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="relative -top-0.5 h-5">
          <AnimatePresence>
            {Boolean(status === Statuses.SUCCESS) && (
              <motion.div
                initial={{ opacity: 0, translateY: 4 }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: -4 }}
                transition={{ duration: 0.15 }}>
                <div className="grid grid-cols-[20px_auto] items-center gap-2 whitespace-nowrap">
                  <CheckIcon />
                  <P className="truncate" testID={successMessageTestId}>
                    {successMessage}
                  </P>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className="flex items-center">
        {secondaryButtonText && secondaryButtonOnClick && formState !== FormStates.SUCCESS && (
          <Button
            onClick={secondaryButtonOnClick}
            theme="quaternary"
            tabIndex={1}
            size="sm"
            className="mr-3 hocus:opacity-50 hocus:shadow-none"
            disabled={buttonsDisabled}>
            {secondaryButtonText}
          </Button>
        )}
        <Button
          type="submit"
          tabIndex={0}
          size="sm"
          testID={testID}
          status={buttonsDisabled ? undefined : formState}
          disabled={buttonsDisabled}
          {...submitButtonProps}>
          {submitButtonText}
        </Button>
      </div>
    </div>
  );
}
