import { mergeClasses } from '@expo/styleguide';

import { Button, ButtonProps } from './index';

export function MarketingWhiteButton({ children, className, size = 'lg', ...rest }: ButtonProps) {
  return (
    <Button
      size={size}
      className={mergeClasses(
        'border-palette-white bg-palette-white text-palette-black',
        'hocus:bg-palette-white hocus:opacity-80',
        className
      )}
      {...rest}>
      {children}
    </Button>
  );
}
