import * as Analytics from '~/common/analytics';
import { PageProps } from '~/scenes/_app/helpers';
import { MarketingWhiteButton } from '~/ui/components/Button/MarketingWhiteButton';
import { H1 } from '~/ui/components/text';

type Props = {
  currentUser?: PageProps['currentUser'];
  title: string;
  href: string;
  buttonText: string;
};

export function EASCallToAction({ currentUser, title, href, buttonText }: Props) {
  return (
    <div className="relative flex flex-col items-center rounded-xl bg-[#4630EB] bg-eas-landing-cta-pattern bg-center px-6 py-24 max-md-gutters:rounded-none">
      <H1 size="xl" className="mb-8 max-w-[650px] text-center text-palette-white">
        {title}
      </H1>
      <MarketingWhiteButton
        size="xl"
        href={href}
        onClick={() =>
          Analytics.track(Analytics.events.EAS_SUBSCRIPTION_FLOW, {
            step: Analytics.EASSubscriptionFlowSteps.CLICK_PRICING_GET_STARTED_BUTTON,
            userId: currentUser?.id,
            accountId: currentUser?.primaryAccount?.id ?? null,
          })
        }>
        {buttonText}
      </MarketingWhiteButton>
    </div>
  );
}
