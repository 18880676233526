import { mergeClasses } from '@expo/styleguide';
import { Mail01Icon } from '@expo/styleguide-icons/outline/Mail01Icon';
import { FormEvent, useState } from 'react';
import * as yup from 'yup';

import { useMailchimpEmailSubscription } from '~/graphql/mutations/MailchimpEmailSubscription.mutation.generated';
import { MailchimpAudience, MailchimpTag } from '~/graphql/types.generated';
import { BoxContentContainer } from '~/ui/components/Box/BoxContentContainer';
import { BoxHeader } from '~/ui/components/Box/BoxHeader';
import { BoxWrapper } from '~/ui/components/Box/BoxWrapper';
import { Form, getValidatedFormFieldDataAsync } from '~/ui/components/form/Form';
import { FormContent } from '~/ui/components/form/FormContent';
import { FormError } from '~/ui/components/form/FormError';
import { FormFooter } from '~/ui/components/form/FormFooter';
import { FormGroup } from '~/ui/components/form/FormGroup';
import { FormStates } from '~/ui/components/form/FormStates';
import { Input } from '~/ui/components/form/Input';
import { ValidationErrors } from '~/ui/components/form/ValidationErrors';
import { P } from '~/ui/components/text';

type Props = {
  email?: string | null;
  className?: string;
};

export function EASMailingList({ email, className }: Props) {
  const [formState, setFormState] = useState(FormStates.IDLE);
  const [formError, setFormError] = useState<string | undefined>();
  const [errors, setErrors] = useState<ValidationErrors | null>(null);
  const [addUser] = useMailchimpEmailSubscription();

  async function onSubmitAsync(event: FormEvent) {
    setErrors(null);
    setFormError(undefined);

    const yupSchema = yup.object({
      email: yup.string().email().required(),
    });

    const [{ email: _email }, validationErrors] = await getValidatedFormFieldDataAsync(
      event,
      yupSchema
    );

    const email = _email?.trim();

    if (validationErrors) {
      return setErrors(validationErrors);
    }

    try {
      setFormState(FormStates.LOADING);

      await addUser({
        variables: {
          addUserInput: {
            email,
            tags: [MailchimpTag.EasMasterList],
            audience: MailchimpAudience.ExpoDevelopers,
          },
        },
      });

      setFormState(FormStates.SUCCESS);
    } catch (error) {
      setFormError((error as Error).message);
      setFormState(FormStates.IDLE);
    }
  }

  return (
    <BoxWrapper
      className={mergeClasses(
        'rounded-lg',
        'max-md-gutters:rounded-none max-md-gutters:border-0',
        className
      )}>
      <BoxContentContainer
        className={mergeClasses('grid grid-cols-2 gap-8 p-10', 'max-md-gutters:grid-cols-1')}>
        <div className="flex flex-col gap-3">
          <BoxHeader
            title="There’s a lot more on the way"
            icon={<Mail01Icon className="icon-lg" />}
          />
          <P theme="secondary">
            Expo Application Services has many new features on the horizon. Stay up to date with our
            newsletter.
          </P>
        </div>
        <Form onSubmit={onSubmitAsync} disabled={formState !== FormStates.IDLE} variant="shadow">
          <FormContent>
            <FormGroup title="Subscribe to the EAS newsletter">
              <Input
                id="email"
                placeholder="you@example.com"
                error={errors?.email}
                aria-label="Email"
                defaultValue={email ?? undefined}
              />
            </FormGroup>
            <FormError error={formError} />
          </FormContent>
          <FormFooter
            submitButtonText="Subscribe"
            formState={formState}
            successMessage="Subscribed"
            formError={formError}
          />
        </Form>
      </BoxContentContainer>
    </BoxWrapper>
  );
}
